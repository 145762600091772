import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

/** Middlewares */
import AppMiddleware from "./AppMiddleware";

/** Reducers */
import lightboxReducer from './Reducers/Lightbox/LightboxSlice';
import projectReducer from './Reducers/Project/ProjectSlice';
import eventsReducer from './Reducers/Project/EventsSlice';
import userReducer from './Reducers/User/UserSlice';
import appReducer from './Reducers/App/AppSlice';
import categoryReducer from './Reducers/App/CategorySlice';


export const store = configureStore({
  reducer: {
    lightbox: lightboxReducer,
    project: projectReducer,
    user: userReducer,
    app: appReducer,
    event: eventsReducer,
    category: categoryReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(AppMiddleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
