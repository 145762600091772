import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface LightboxState {
	current: number;
	status: "open" | "close";
	pictures: any;
	video: any;
}

const initialState: LightboxState = {
	current: 0,
	status: "close",
	pictures: [],
	video: {},
};

export const lightboxSlice = createSlice({
	name: "lightbox",
	initialState,
	reducers: {
		setPictures: (state, action: PayloadAction) => {
			state.pictures = action.payload;
		},
		setVideo: (state, action: PayloadAction) => {
			state.video = action.payload;
		},
		setCurrent: (state, action: PayloadAction<number>) => {
			state.current = action.payload;
		},
		open: (state) => {
			state.status = "open";
		},
		close: (state) => {
			state.status = "close";
		},
	},
});

export const { open, close, setPictures, setCurrent, setVideo } =
	lightboxSlice.actions;
export const getStatus = (state: RootState) => state.lightbox.status;
export const getPictures = (state: RootState) => state.lightbox.pictures;
export const getVideo = (state: RootState) => state.lightbox.video;
export const getCurrent = (state: RootState) => state.lightbox.current;

export default lightboxSlice.reducer;
