import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";

export interface CategoryState {
  professions : any,
  contractors : any,
}

const initialState: CategoryState = {
  professions: [],
  contractors: [],
  
};

export const CategorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
  
    loadContractors: (state, action: PayloadAction<any>) => {},
    loadCategories: (state, action: PayloadAction<any>) => {},

    setProfessions: (state, action: PayloadAction<any>) => {
        state.professions = action.payload
    },

    setContractors: (state, action: PayloadAction<any>) => {
      state.contractors = action.payload
  },
   
  },
});

export const { setProfessions,setContractors,loadCategories,loadContractors } = CategorySlice.actions;

export const getProfessions = (state: RootState) => state.category.professions;
export const getContractors = (state: RootState) => state.category.contractors;


export default CategorySlice.reducer;
