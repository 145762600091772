import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";
import { initialState } from "Types/Project";

export const ProjectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    clearProject: (state, action: PayloadAction<any>) => {
      state = initialState;
    },
    loadProject: (state, action: PayloadAction<any>) => {},
    setProject: (state, action: PayloadAction<any>) => {
      state.id = action.payload?.id ? action.payload?.id : state.id;
      state.datas = action.payload?.datas ? action.payload?.datas : state.datas;
      state.attributes = action.payload?.attributes
        ? action.payload?.attributes
        : state.attributes;
      state.loading = action.payload?.loading
        ? action.payload?.loading
        : state.loading;
    },
    deleteProject: (state, action: PayloadAction<any>) => {
      state = initialState;
    },
    setContractors: (state, action: PayloadAction<any>) => {
      state.contractors = action.payload;
    },
    setCoordinates: (state, action: PayloadAction<any>) => {
      state.coordinates = action.payload;
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload ? true : false;
    },
    
  },
});

export const {
  loadProject,
  setProject,
  deleteProject,
  clearProject,
  setContractors,
  setLoading,
  setCoordinates
} = ProjectSlice.actions;

export const getProject = (state: RootState) => state.project;
export const getContractors = (state: RootState) => state.project.contractors;
export const getProjectLoading = (state: RootState) => state.project.loading;
export const getCoordinates = (state: RootState) => state.project.coordinates;

export default ProjectSlice.reducer;
